<template>

  <div class="row" ref="productForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <ValidationProvider
                      vid="title"
                      rules="required"
                      name="The Title"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Title"
                                name="title"
                                fou
                                @keyup="generateSomeFields"
                                v-model="formData.title">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <ValidationProvider
                      vid="url"
                      rules="required"
                      name="The URL"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="URL"
                                name="ident"
                                fou
                                v-model="formData.url">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <ValidationProvider
                  vid="brief"
                  rules=""
                  name="The Brief"
                  v-slot="{ passed, failed,errors }">
                  <fg-text type="text"
                           :error="failed ? errors[0]: null"
                           label="Brief"
                           name="brief"
                           fou
                           v-model="formData.brief">
                  </fg-text>
                </ValidationProvider>
              </div>
              <div class="col-md-12 col-sm-12">
                <ValidationProvider
                  vid="quote"
                  rules=""
                  name="The Quote"
                  v-slot="{ passed, failed,errors }">
                  <fg-text type="quote"
                           :error="failed ? errors[0]: null"
                           label="Quote"
                           name="quote"
                           fou
                           v-model="formData.quote">
                  </fg-text>
                </ValidationProvider>
              </div>
              <div class="col-md-12 col-sm-12">
                <ValidationProvider
                  vid="quote_source"
                  rules=""
                  name="The Author"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Author"
                            name="quote_source"
                            fou
                            v-model="formData.quote_source">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Content</label>
                  <editor
                    v-model="formData.content"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>
              </div>
              <div class="col-md-12 col-sm-12">

                <div class="form-group">
                  <label>Image</label>
                  <el-tooltip placement="right">
                    <div slot="content">(534 × 752)</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.image"
                  >
                  </prime-uploader>
                </div>
                <div class="row">
                  <div class="col-md-12 col-sm-12">
                    <ValidationProvider
                      vid="location"
                      rules=""
                      name="The Location"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Location"
                                name="location"
                                fou
                                v-model="formData.location">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="card-label">Date</label>
                    <fg-input v-model="formData.date"
                              value-format="YYYY-MM-DD HH:MM:SS"
                              type="datetime-local"
                              placeholder="Chose Date"
                    >
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <label>Facts</label>
                  <div class="row col-md-12 mt-2">
                    <template class="row col-md-12" v-for="(fact,factIndex) in formData.facts">
                      <div class="col-md-6">
                        <fg-input type="text"
                                  :key="'title-'+factIndex"
                                  :label="'Fact Title'+(factIndex+1)"
                                  v-model="formData.facts[factIndex].title">
                        </fg-input>
                        <fg-input type="text"
                                  :key="'value-'+factIndex"
                                  :label="'Fact Value '+(factIndex+1)"
                                  v-model="formData.facts[factIndex].value">
                        </fg-input>
                        <div class="form-group">
                          <label>Fact Icon {{(factIndex+1)}}</label>
                          <el-tooltip placement="right">
                            <div slot="content">(64 × 64)</div>
                            <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                          </el-tooltip>
                          <prime-uploader
                            :preview-width="'200px'"
                            :preview-height="'200px'"
                            :elementNum="1"
                            :key="'icon-'+factIndex"
                            v-model="formData.facts[factIndex].image"
                          >
                          </prime-uploader>
                        </div>
                        <div class="col-sm-1 align-self-center mt-3">
                          <l-button
                            @click="removeFact(formData.facts, factIndex)"
                            type="danger" size="sm">
                            <i class="fa fa-trash"></i>
                          </l-button>
                        </div>
                      </div>
                    </template>
                    <div>
                      <l-button
                        class="mt-3"
                        type="success"
                        @click="addNewFact(formData.facts)"
                        size="sm">
                        <i class="fa fa-plus"></i>
                      </l-button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Gallery</label>
                      <el-tooltip placement="right"  >
                        <div slot="content">(395 × 345)</div>
                        <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                      </el-tooltip>
                      <prime-uploader
                        :preview-width="'200px'"
                        :preview-height="'200px'"
                        :elementNum="999"
                        :maxFileSize="2000000"
                        :isEditMode="editMode"
                        v-model="formData.gallery"
                      >
                      </prime-uploader>
                    </div>
                  </div>
                </div>
                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>

              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/projects/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        title: "",
        url: "",
        brief: '',
        content: "",
        image: "",
        quote: "",
        quote_source: "",
        location: "",
        date: "",
        is_active: true,
        facts: [{
          id: '',
          title: '',
          value: '',
          image: '',
        }],
        gallery: [],
      },

    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.productForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Project";
      this.getProduct();
    } else {
      this.editMode = false;
      this.formTitle = "Add Project";
      this.loader.hide();
    }
  },


  methods: {
    getProduct() {
      let data = {
        'id': this.id,
      };
      this.axios.post("products/get", data).then((response) => {
        this.formData = response.data;
        this.vueDate();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Project Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      this.laravelDate();
      if (this.editMode === true) {
        request = this.axios.put("products/update/" + this.id, this.formData);
        successMessage = "Project Updated Successfully";
      } else {
        request = this.axios.post("products/create", this.formData);
        successMessage = "Project Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/projects/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

    getBannerImageInfo() {
      if (this.mediaInfo && this.mediaInfo["prodcut_image"]) {
        return this.mediaInfo["prodcut_image"];
      } else {
        return "";
      }
    },
    addNewFact(facts) {
      facts.push({id: '', title: '', value: '', image: ''});
    },
    removeFact(facts, index) {
      facts.splice(index, 1);
    },
    vueDate() {
      this.formData.date = (this.formData.date) ? this.formData.date.replace(' ', 'T') : this.formData.date;
    },
    laravelDate() {
      this.formData.date = (this.formData.date) ? this.formData.date.replace('T', ' ') : this.formData.date;
    },
    generateSomeFields() {
      let newUrl = '';
      if (this.formData.title) {
        newUrl = this.formData.title.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "_");
      }
      this.formData.url = newUrl;
    },
  }
}
</script>
